import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
  },
  strict: process.env.DEV,

  state: {
    userDataStore: JSON.parse(localStorage.getItem('userData')) || {} // Recupera i dati iniziali da localStorage
  },
  mutations: {
    setUserData(state, userData) {
      state.userDataStore = userData;
      localStorage.setItem('userData', JSON.stringify(userData)); // Aggiorna anche in localStorage
    },
    updateUserName(state, newName) {
      state.userDataStore.name = newName;
      localStorage.setItem('userData', JSON.stringify(state.userDataStore)); // Aggiorna anche in localStorage
    },
    updateUserSurname(state, newSurname) {
      state.userDataStore.surname = newSurname;
      localStorage.setItem('userData', JSON.stringify(state.userDataStore)); // Aggiorna anche in localStorage
    }
  },
  actions: {    
    setUserData({ commit }, userData) {
      commit('setUserData', userData);
    },
    updateUserName({ commit }, newName) {
      commit('updateUserName', newName);
    },
    updateUserSurname({ commit }, newSurname) {
      commit('updateUserSurname', newSurname);
    }
  }
})
