export default [
    {
        path: '/crud',
        name: 'crud-utenti',
        component: () => import('@/views/crud/utenti.vue'),
        meta: {
            pageTitle: 'CRUD Demo',
            action: 'employee',
            resource: 'manage',
            breadcrumb: [
              {
                text: 'Area Demo',
                active: true
              },
            ],
        },
    },
    {
        path: '/crud/goodtable',
        name: 'crud-goodtable',
        component: () => import('@/views/crud/goodtable.vue'),
        meta: {
            pageTitle: 'GoodTable',
            action: 'employee',
            resource: 'manage',
            breadcrumb: [
              {
                text: 'Area Demo',
                to: '/crud',
              },
              {
                text: 'Good Table',
                active: true,
              },
            ],
        },
    },
    {
        path: '/autologout',
        name: 'crud-autologout',
        component: () => import('@/views/crud/autologout.vue'),
        meta: {
            pageTitle: 'Autologout',
            action: 'employee',
            resource: 'manage',
            breadcrumb: [
              {
                text: 'Area Demo',
                active: true
              },
            ],
        },
    },
]
