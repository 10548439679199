export default [
    {
        path: '/scrivania',
        name: 'home-agentscout',
        component: () => import('@/views/scrivania/home.vue'),
        meta: {
            pageTitle: 'Home',
            action: 'employee',
            resource: 'read',
        },
    },
]
