import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  tableBasic: [
    {
      id: 1,
      nome: "Andrea",
      cognome: "De Matthais",
      email: 'andrea.demathais@demo.it',
      cellulare: '333.123456',
      ruolo: 'Admin',
      stato: 'Attivo',
    },
    {
      id: 2,
      nome: "Mauro",
      cognome: "montrucchio",
      email: 'm.montrucchio@demo.it',
      cellulare: '123.741589',
      ruolo: 'Manager',
      stato: 'Attivo',
    },
    {
      id: 3,
      nome: "Andrea",
      cognome: "Mori",
      email: 'a.mori@demo.it',
      cellulare: '329.951235',
      ruolo: 'Developer',
      stato: 'Attivo',
    },
    {
      id: 4,
      nome: "Emanuele",
      cognome: "Cognome",
      email: 'emanuele@demo.it',
      cellulare: '055.123458',
      ruolo: 'Developer',
      stato: 'Attivo',
    },
    {
      id: 5,
      nome: "Carlo",
      cognome: "Bianchi",
      email: 'bianchi@dominioabc123.it',
      cellulare: '050.885522',
      ruolo: 'Assistente',
      stato: 'Disattivo',
    },
    {
      id: 6,
      nome: "Martina",
      cognome: "Rossi",
      email: 'rossi@dominioabc123.it',
      cellulare: '050.854123',
      ruolo: 'Assistente',
      stato: 'Attivo',
    },
    {
      id: 1,
      nome: "Andrea",
      cognome: "De Matthais",
      email: 'andrea.demathais@demo.it',
      cellulare: '333.123456',
      ruolo: 'Admin',
      stato: 'Attivo',
    },
    {
      id: 2,
      nome: "Mauro",
      cognome: "montrucchio",
      email: 'm.montrucchio@demo.it',
      cellulare: '123.741589',
      ruolo: 'Manager',
      stato: 'Attivo',
    },
    {
      id: 3,
      nome: "Andrea",
      cognome: "Mori",
      email: 'a.mori@demo.it',
      cellulare: '329.951235',
      ruolo: 'Developer',
      stato: 'Attivo',
    },
    {
      id: 4,
      nome: "Emanuele",
      cognome: "Cognome",
      email: 'emanuele@demo.it',
      cellulare: '055.123458',
      ruolo: 'Developer',
      stato: 'Attivo',
    },
    {
      id: 5,
      nome: "Carlo",
      cognome: "Bianchi",
      email: 'bianchi@dominioabc123.it',
      cellulare: '050.885522',
      ruolo: 'Assistente',
      stato: 'Disattivo',
    },
    {
      id: 6,
      nome: "Martina",
      cognome: "Rossi",
      email: 'rossi@dominioabc123.it',
      cellulare: '050.854123',
      ruolo: 'Assistente',
      stato: 'Attivo',
    },
    {
      id: 1,
      nome: "Andrea",
      cognome: "De Matthais",
      email: 'andrea.demathais@demo.it',
      cellulare: '333.123456',
      ruolo: 'Admin',
      stato: 'Attivo',
    },
    {
      id: 2,
      nome: "Mauro",
      cognome: "montrucchio",
      email: 'm.montrucchio@demo.it',
      cellulare: '123.741589',
      ruolo: 'Manager',
      stato: 'Attivo',
    },
    {
      id: 3,
      nome: "Andrea",
      cognome: "Mori",
      email: 'a.mori@demo.it',
      cellulare: '329.951235',
      ruolo: 'Developer',
      stato: 'Attivo',
    },
    {
      id: 4,
      nome: "Emanuele",
      cognome: "Cognome",
      email: 'emanuele@demo.it',
      cellulare: '055.123458',
      ruolo: 'Developer',
      stato: 'Attivo',
    },
    {
      id: 5,
      nome: "Carlo",
      cognome: "Bianchi",
      email: 'bianchi@dominioabc123.it',
      cellulare: '050.885522',
      ruolo: 'Assistente',
      stato: 'Disattivo',
    },
    {
      id: 6,
      nome: "Martina",
      cognome: "Rossi",
      email: 'rossi@dominioabc123.it',
      cellulare: '050.854123',
      ruolo: 'Assistente',
      stato: 'Attivo',
    },
    {
      id: 1,
      nome: "Andrea",
      cognome: "De Matthais",
      email: 'andrea.demathais@demo.it',
      cellulare: '333.123456',
      ruolo: 'Admin',
      stato: 'Attivo',
    },
    {
      id: 2,
      nome: "Mauro",
      cognome: "montrucchio",
      email: 'm.montrucchio@demo.it',
      cellulare: '123.741589',
      ruolo: 'Manager',
      stato: 'Attivo',
    },
    {
      id: 3,
      nome: "Andrea",
      cognome: "Mori",
      email: 'a.mori@demo.it',
      cellulare: '329.951235',
      ruolo: 'Developer',
      stato: 'Attivo',
    },
    {
      id: 4,
      nome: "Emanuele",
      cognome: "Cognome",
      email: 'emanuele@demo.it',
      cellulare: '055.123458',
      ruolo: 'Developer',
      stato: 'Attivo',
    },
    {
      id: 5,
      nome: "Carlo",
      cognome: "Bianchi",
      email: 'bianchi@dominioabc123.it',
      cellulare: '050.885522',
      ruolo: 'Assistente',
      stato: 'Disattivo',
    },
    {
      id: 6,
      nome: "Martina",
      cognome: "Rossi",
      email: 'rossi@dominioabc123.it',
      cellulare: '050.854123',
      ruolo: 'Assistente',
      stato: 'Attivo',
    },
    {
      id: 1,
      nome: "Andrea",
      cognome: "De Matthais",
      email: 'andrea.demathais@demo.it',
      cellulare: '333.123456',
      ruolo: 'Admin',
      stato: 'Attivo',
    },
    {
      id: 2,
      nome: "Mauro",
      cognome: "montrucchio",
      email: 'm.montrucchio@demo.it',
      cellulare: '123.741589',
      ruolo: 'Manager',
      stato: 'Attivo',
    },
    {
      id: 3,
      nome: "Andrea",
      cognome: "Mori",
      email: 'a.mori@demo.it',
      cellulare: '329.951235',
      ruolo: 'Developer',
      stato: 'Attivo',
    },
    {
      id: 4,
      nome: "Emanuele",
      cognome: "Cognome",
      email: 'emanuele@demo.it',
      cellulare: '055.123458',
      ruolo: 'Developer',
      stato: 'Attivo',
    },
    {
      id: 5,
      nome: "Carlo",
      cognome: "Bianchi",
      email: 'bianchi@dominioabc123.it',
      cellulare: '050.885522',
      ruolo: 'Assistente',
      stato: 'Disattivo',
    },
    {
      id: 6,
      nome: "Martina",
      cognome: "Rossi",
      email: 'rossi@dominioabc123.it',
      cellulare: '050.854123',
      ruolo: 'Assistente',
      stato: 'Attivo',
    },

  ],
  
  /* eslint-disable global-require */
}

mock.onGet('/demo-utenti/basic').reply(() => [200, data.tableBasic])
