export default [
    {
        path: '/attivita',
        name: 'attivita-index',
        //component: () => import('@/views/tools/log/index.vue'),
        component: () => import('@/views/attivita/task-index.vue'),
        meta: {
            pageTitle: 'Attività',
            action: 'employee',
            resource: 'read',
            breadcrumb: [
              {
                text: 'Attività',
                active: true,
              },
            ],
            breadcrumb_btn_viewed: '0',
            breadcrumb_btn_variant: '',
            breadcrumb_btn_icon: '',
            breadcrumb_btn_to: {},
            
            contentRenderer: 'sidebar-left',
            contentClass: 'todo-application',
        },
    },
    {
        path: '/attivita/show/:id_riga',
        name: 'attivita-show',
        component: () => import('@/views/attivita/show.vue'),
        meta: {
            pageTitle: 'Attività',
            action: 'employee',
            resource: 'read',
            breadcrumb: [
                {
                  text: 'Attività',
                },
                {
                  text: 'Scheda',
                  active: true,
                },
            ],
            breadcrumb_btn_viewed: '1',
            breadcrumb_btn_variant: 'primary',
            breadcrumb_btn_icon: 'ArrowLeftIcon',
            breadcrumb_btn_to: {name: 'attivita-index' },
        },
    },
    
]
