export default [
    {
        path: '/tools/log',
        name: 'tools-log-index',
        component: () => import('@/views/tools/log/index.vue'),
        meta: {
            pageTitle: 'Log',
            action: 'employee',
            resource: 'manage',
            breadcrumb: [
              {
                text: 'Tools',
              },
              {
                text: 'Log',
                active: true,
              },
            ],
            breadcrumb_btn_viewed: '0',
            breadcrumb_btn_variant: '',
            breadcrumb_btn_icon: '',
            breadcrumb_btn_to: {},
        },
    },
    {
        path: '/tools/log/show/:id_riga',
        name: 'tools-log-show',
        component: () => import('@/views/tools/log/show.vue'),
        meta: {
            pageTitle: 'Scheda Log',
            action: 'employee',
            resource: 'manage',
            breadcrumb: [
                {
                  text: 'Tools',
                },
                {
                  text: 'Log',
                  to: {name: 'tools-log-index' },
                },
              {
                text: 'Scheda Log',
                active: true,
              },
            ],
            breadcrumb_btn_viewed: '1',
            breadcrumb_btn_variant: 'primary',
            breadcrumb_btn_icon: 'ArrowLeftIcon',
            breadcrumb_btn_to: {name: 'tools-log-index' },
        },
    },
    {
        path: '/tools/emailtemplate',
        name: 'tools-emailtemplate-index',
        component: () => import('@/views/tools/emailtemplate/index.vue'),
        meta: {
            pageTitle: 'Email Template',
            action: 'employee',
            resource: 'manage',
            breadcrumb: [
              {
                text: 'Tools',
              },
              {
                text: 'Email Template',
                active: true,
              },
            ],
            breadcrumb_btn_viewed: '1',
            breadcrumb_btn_variant: 'primary',
            breadcrumb_btn_icon: 'PlusIcon',
            breadcrumb_btn_to: {name: 'tools-emailtemplate-new' },
        },
    },
    {
        path: '/tools/emailtemplate/show/:id_riga',
        name: 'tools-emailtemplate-show',
        component: () => import('@/views/tools/emailtemplate/show.vue'),
        meta: {
            pageTitle: 'Visualizza Email Template',
            action: 'employee',
            resource: 'manage',
            breadcrumb: [
              {
                text: 'Tools',
              },
              {
                text: 'Lista Email Template',
                to: {name: 'tools-emailtemplate-index' },
              },
              {
                text: 'Visualizza',
                active: true,
              },
            ],
            breadcrumb_btn_viewed: '1',
            breadcrumb_btn_variant: 'primary',
            breadcrumb_btn_icon: 'ArrowLeftIcon',
            breadcrumb_btn_to: {name: 'tools-emailtemplate-index' },
        },
    },
    {
        path: '/tools/emailtemplate/new',
        name: 'tools-emailtemplate-new',
        component: () => import('@/views/tools/emailtemplate/new.vue'),
        meta: {
            pageTitle: 'Email Template',
            action: 'employee',
            resource: 'manage',
            breadcrumb: [
              {
                text: 'Tools',
              },
              {
                text: 'Lista Email Template',
                to: {name: 'tools-emailtemplate-index' },
              },
              {
                text: 'Nuova Email Template',
                active: true,
              },
            ],
            breadcrumb_btn_viewed: '0',
            breadcrumb_btn_variant: '',
            breadcrumb_btn_icon: '',
            breadcrumb_btn_to: {},
        },
    },
    {
        path: '/tools/emailtemplate/edit/:id_riga',
        name: 'tools-emailtemplate-edit',
        component: () => import('@/views/tools/emailtemplate/edit.vue'),
        meta: {
            pageTitle: 'Modifica Email Template',
            action: 'employee',
            resource: 'manage',
            breadcrumb: [
              {
                text: 'CRM',
              },
              {
                text: 'Lista Email Template',
                to: {name: 'tools-emailtemplate-index' },
              },
              {
                text: 'Modifica Email Template',
                active: true,
              },
            ],
        },
    },
    {
        path: '/tools/emailtemplate/duplic/:id_riga',
        name: 'tools-emailtemplate-duplic',
        component: () => import('@/views/tools/emailtemplate/duplic.vue'),
        meta: {
            pageTitle: 'Copia Email Template',
            action: 'employee',
            resource: 'manage',
            breadcrumb: [
              {
                text: 'CRM',
              },
              {
                text: 'Lista Email Template',
                to: {name: 'tools-emailtemplate-index' },
              },
              {
                text: 'Copia Email Template',
                active: true,
              },
            ],
        },
    },
    {
        path: '/tools/contrattitemplate',
        name: 'tools-contracttemplate-index',
        component: () => import('@/views/tools/contracttemplate/index.vue'),
        meta: {
            pageTitle: 'Contratti Template',
            action: 'employee',
            resource: 'manage',
            breadcrumb: [
              {
                text: 'Tools',
              },
              {
                text: 'Contratti Template',
                active: true,
              },
            ],
            breadcrumb_btn_viewed: '1',
            breadcrumb_btn_variant: 'primary',
            breadcrumb_btn_icon: 'PlusIcon',
            breadcrumb_btn_to: {name: 'tools-contracttemplate-new' },
        },
    },
    {
        path: '/tools/contrattitemplate/show/:id_riga',
        name: 'tools-contracttemplate-show',
        component: () => import('@/views/tools/contracttemplate/show.vue'),
        meta: {
            pageTitle: 'Visualizza Contratti Template',
            action: 'employee',
            resource: 'manage',
            breadcrumb: [
              {
                text: 'Tools',
              },
              {
                text: 'Lista Contratti Template',
                to: {name: 'tools-contracttemplate-index' },
              },
              {
                text: 'Visualizza',
                active: true,
              },
            ],
            breadcrumb_btn_viewed: '1',
            breadcrumb_btn_variant: 'primary',
            breadcrumb_btn_icon: 'ArrowLeftIcon',
            breadcrumb_btn_to: {name: 'tools-contracttemplate-index' },
        },
    },
    {
        path: '/tools/contrattitemplate/new',
        name: 'tools-contracttemplate-new',
        component: () => import('@/views/tools/contracttemplate/new.vue'),
        meta: {
            pageTitle: 'Contratto Template',
            action: 'employee',
            resource: 'manage',
            breadcrumb: [
              {
                text: 'Tools',
              },
              {
                text: 'Lista Contratto Template',
                to: {name: 'tools-contracttemplate-index' },
              },
              {
                text: 'Nuovo Contratto Template',
                active: true,
              },
            ],
            breadcrumb_btn_viewed: '0',
            breadcrumb_btn_variant: '',
            breadcrumb_btn_icon: '',
            breadcrumb_btn_to: {},
        },
    },
    {
        path: '/tools/contrattitemplate/edit/:id_riga',
        name: 'tools-contracttemplate-edit',
        component: () => import('@/views/tools/contracttemplate/edit.vue'),
        meta: {
            pageTitle: 'Modifica Contratto Template',
            action: 'employee',
            resource: 'manage',
            breadcrumb: [
              {
                text: 'CRM',
              },
              {
                text: 'Lista Contratti Template',
                to: {name: 'tools-contracttemplate-index' },
              },
              {
                text: 'Modifica Contratto Template',
                active: true,
              },
            ],
        },
    },
    {
        path: '/tools/contrattitemplate/duplic/:id_riga',
        name: 'tools-contracttemplate-duplic',
        component: () => import('@/views/tools/contracttemplate/duplic.vue'),
        meta: {
            pageTitle: 'Copia Contratto Template',
            action: 'employee',
            resource: 'manage',
            breadcrumb: [
              {
                text: 'CRM',
              },
              {
                text: 'Lista Contratti Template',
                to: {name: 'tools-contracttemplate-index' },
              },
              {
                text: 'Copia Contratto Template',
                active: true,
              },
            ],
        },
    },
    {
      path: '/impostazioni',
      name: 'tools-impostazioni-index',
      component: () => import('@/views/tools/impostazioni/index.vue'),
      meta: {
          pageTitle: 'Impostazioni',
          action: 'employee',
          resource: 'manage',
          breadcrumb: [
            {
              text: 'Impostazioni',
              active: true,
            },
          ],
          breadcrumb_btn_viewed: '0',
          breadcrumb_btn_variant: '',
          breadcrumb_btn_icon: '',
          breadcrumb_btn_to: {},
      },
  },
]
