export default [
    {
        path: '/profilo',
        name: 'profilo-index',
        component: () => import('@/views/profilo/index.vue'),
        meta: {
            pageTitle: 'Profilo Utente',
            action: 'employee',
            resource: 'read',
            breadcrumb: [],
            breadcrumb_btn_viewed: '1',
            breadcrumb_btn_variant: 'primary',
            breadcrumb_btn_icon: 'Edit2Icon',
            breadcrumb_btn_to: {name: 'profilo-edit' },
        },
    },
    {
        path: '/profilo/edit',
        name: 'profilo-edit',
        component: () => import('@/views/profilo/edit.vue'),
        meta: {
            pageTitle: 'Modifica Profilo Utente',
            action: 'employee',
            resource: 'read',
            breadcrumb: [
              {
                text: 'Profilo Utente',
                to: {name: 'profilo-index' },
              },
            ],
        },
    },
    {
        path: '/profilo/firma-email',
        name: 'profilo-firma-email',
        component: () => import('@/views/profilo/firmaemail.vue'),
        meta: {
            pageTitle: 'Modifica Firma Email',
            action: 'employee',
            resource: 'read',
            breadcrumb: [
              {
                text: 'Profilo Utente',
                to: {name: 'profilo-index' },
              },
            ],
        },
    },
    {
        path: '/profilo/change-password',
        name: 'profilo-change-password',
        component: () => import('@/views/profilo/change-password.vue'),
        meta: {
            pageTitle: 'Cambio password',
            action: 'employee',
            resource: 'read',
            breadcrumb: [
              {
                text: 'Profilo Utente',
                to: {name: 'profilo-index' },
              },
            ],
        },
    },

]