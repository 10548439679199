export default [
  {
      path: '/activities',
      name: 'activities-index-in-corso',
      component: () => import('@/views/activities/index.vue'),
      meta: {
          pageTitle: 'Attività',
          action: 'employee',
          resource: 'read',
          breadcrumb: [
            {
              text: 'Attività',
              active: true,
            },
          ],
          breadcrumb_btn_viewed: '0',
          breadcrumb_btn_variant: 'primary',
          breadcrumb_btn_icon: 'PlusIcon',
          breadcrumb_btn_to: {name: '' },
      },
  },
  {
      path: '/activities/completata',
      name: 'activities-index-completata',
      component: () => import('@/views/activities/index-completata.vue'),
      meta: {
          pageTitle: 'Attività',
          action: 'employee',
          resource: 'read',
          breadcrumb: [
            {
              text: 'Attività',
              active: true,
            },
          ],
          breadcrumb_btn_viewed: '0',
          breadcrumb_btn_variant: 'primary',
          breadcrumb_btn_icon: 'PlusIcon',
          breadcrumb_btn_to: {name: '' },
      },
  },
  {
      path: '/activities/annullata',
      name: 'activities-index-annullata',
      component: () => import('@/views/activities/index-annullata.vue'),
      meta: {
          pageTitle: 'Attività',
          action: 'employee',
          resource: 'read',
          breadcrumb: [
            {
              text: 'Attività',
              active: true,
            },
          ],
          breadcrumb_btn_viewed: '0',
          breadcrumb_btn_variant: 'primary',
          breadcrumb_btn_icon: 'PlusIcon',
          breadcrumb_btn_to: {name: '' },
      },
  },
    
]
