export default [
  {
    path: '/crm/ricerche',
    name: 'crm-ricerche-index',
    component: () => import('@/views/crm/ricerche/index.vue'),
    meta: {
        pageTitle: 'Ricerche',
        action: 'employee',
        resource: 'read',
        breadcrumb: [
          {
            text: 'CRM',
          },
          {
            text: 'Ricerche',
            active: true,
          },
        ],
        breadcrumb_btn_viewed: '1',
        breadcrumb_btn_variant: 'primary',
        breadcrumb_btn_icon: 'PlusIcon',
        breadcrumb_btn_to: {name: 'crm-ricerche-new' },
    },
  },
  {
      path: '/crm/ricerche/show/:id_riga',
      name: 'crm-ricerche-show',
      component: () => import('@/views/crm/ricerche/show.vue'),
      meta: {
          pageTitle: 'Scheda Ricerca',
          action: 'employee',
          resource: 'read',
          breadcrumb: [
            {
              text: 'CRM',
            },
            {
              text: 'Ricerche',
              to: {name: 'crm-ricerche-index' },
            },
            {
              text: 'Scheda Ricerca',
              active: true,
            },
          ],
      },
  },
  {
      path: '/crm/ricerche/new',
      name: 'crm-ricerche-new',
      component: () => import('@/views/crm/ricerche/new.vue'),
      meta: {
          pageTitle: 'Nuova Ricerca',
          action: 'employee',
          resource: 'read',
          breadcrumb: [
            {
              text: 'CRM',
            },
            {
              text: 'Lista Ricerche',
              to: {name: 'crm-ricerche-index' },
            },
            {
              text: 'Nuova Ricerca',
              active: true,
            },
          ],
      },
  },
  {
      path: '/crm/ricerche/edit/:id_riga',
      name: 'crm-ricerche-edit',
      component: () => import('@/views/crm/ricerche/edit.vue'),
      meta: {
          pageTitle: 'Modifica Ricerca',
          action: 'employee',
          resource: 'read',
          breadcrumb: [
            {
              text: 'CRM',
            },
            {
              text: 'Lista Ricerche',
              to: {name: 'crm-ricerche-index' },
            },
            {
              text: 'Modifica Ricerca',
              active: true,
            },
          ],
      },
  },

]
