export default [
    {
        path: '/google-workspace/gmail',
        name: 'google-workspace-gmail-index',
        
        //component: () => import('@/views/apps/email/Email.vue'),
        component: () => import('@/views/google-workspace/gmail/index.vue'),

        meta: {
            pageTitle: 'Gmail',
            action: 'employee',
            //resource: 'manage', //accesso solo per super-admin
            resource: 'read', //accesso per tutti gli employee
            breadcrumb: [
              {
                text: 'Google Workspace',
                active: true,
                //to: {name: 'google-workspace-home' },
              },
              {
                text: 'Gmail',
                active: true,
              },
            ],
            breadcrumb_btn_viewed: '0',
            breadcrumb_btn_variant: 'primary',
            breadcrumb_btn_icon: 'PlusIcon',
            breadcrumb_btn_to: {name: 'crm-utenti-new' },

            contentRenderer: 'sidebar-left',
            contentClass: 'email-application',
        },
    },      
    {
      path: '/google-workspace/gmail/:folder',
      name: 'google-workspace-gmail-folder',

      //component: () => import('@/views/apps/email/Email.vue'),
      component: () => import('@/views/google-workspace/gmail/index.vue'),
      
      meta: {
        
        pageTitle: 'Gmail',
        action: 'employee',
        //resource: 'manage', //accesso solo per super-admin
        resource: 'read', //accesso per tutti gli employee
        breadcrumb: [
          {
            text: 'Google Workspace',
            active: true,
            //to: {name: 'google-workspace-home' },
          },
          {
            text: 'Gmail',
            active: true,
          },
        ],
        breadcrumb_btn_viewed: '0',
        breadcrumb_btn_variant: '',
        breadcrumb_btn_icon: '',
        breadcrumb_btn_to: {},

        contentRenderer: 'sidebar-left',
        contentClass: 'email-application',
        navActiveLink: 'google-workspace-gmail-index',
      },
      beforeEnter(to, _, next) {
        if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
        else next({ name: 'error-404' })
      },
    },
]
