export default [
  {
    path: '/crm/agenti',
    name: 'crm-agenti-index',
    component: () => import('@/views/crm/agenti/index.vue'),
    meta: {
        pageTitle: 'Agenti',
        action: 'employee',
        resource: 'read',
        breadcrumb: [
          {
            text: 'CRM',
          },
          {
            text: 'Agenti',
            active: true,
          },
        ],
        breadcrumb_btn_viewed: '1',
        breadcrumb_btn_variant: 'primary',
        breadcrumb_btn_icon: 'PlusIcon',
        breadcrumb_btn_to: {name: 'crm-agenti-new' },
    },
  },
  {
      path: '/crm/agenti/new',
      name: 'crm-agenti-new',
      component: () => import('@/views/crm/agenti/new.vue'),
      meta: {
          pageTitle: 'Nuovo Agente',
          action: 'employee',
          resource: 'read',
          breadcrumb: [
            {
              text: 'CRM',
            },
            {
              text: 'Lista Agenti',
              to: {name: 'crm-agenti-index' },
            },
            {
              text: 'Nuovo Agente',
              active: true,
            },
          ],
      },
  },
  {
      path: '/crm/agenti/edit/:id_riga',
      name: 'crm-agenti-edit',
      component: () => import('@/views/crm/agenti/edit.vue'),
      meta: {
          pageTitle: 'Modifica Agente',
          action: 'employee',
          resource: 'read',
          breadcrumb: [
            {
              text: 'CRM',
            },
            {
              text: 'Agenti',
              to: {name: 'crm-agenti-index' },
            },
            {
              text: 'Modifica Agente',
              active: true,
            },
          ],
      },
  },
  {
      path: '/crm/agenti/show/:id_riga',
      name: 'crm-agenti-show',
      component: () => import('@/views/crm/agenti/show.vue'),
      meta: {
          pageTitle: 'Scheda Agente',
          action: 'employee',
          resource: 'read',
          breadcrumb: [
            {
              text: 'CRM',
            },
            {
              text: 'Agenti',
              to: {name: 'crm-agenti-index' },
            },
            {
              text: 'Scheda Agente',
              active: true,
            },
          ],
      },
  },

]
