import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  tableLicenze: [
    {
      id: 'XYZ123',
      inizio: '20/10/2022',
      fine: '01/01/2023',
      stato: 'Attiva',
      tipo: 'One Shot',
      prezzo: '990 €',
    },
    {
      id: 'ABC456',
      inizio: '10/06/2022',
      fine: '',
      stato: 'Attiva',
      tipo: 'Abbonamento',
      prezzo: '790 €',
    },
    {
      id: 'ITG652',
      inizio: '16/09/2022',
      fine: '01/01/2023',
      stato: 'Inattiva',
      tipo: 'One Shot',
      prezzo: '1990 €',
    },
  ],

  tablePagamenti: [
    {
      id: 'pm_1Ll8YpIHR2Kx1sUUyA0715zq',
      licenza: 'AYZ123',
      stato: 'Riuscito',
      importo: '975,00 €',
      fattura_xml: '2022/0721',
      data_fattura: '20/10/2022',
      bonifico: '3721,00 €',
      data_bonifico: '24/10/2022',
      id_bonifico: 'AA1122334',
    },
    {
      id: 'pm_dsd8YpIHR2KasddsaUyA0715zq',
      licenza: 'AFT567',
      stato: 'Rimborsato',
      importo: '975,00 €',
      fattura_xml: '2022/0722',
      data_fattura: '21/10/2022',
      bonifico: '1197,00 €',
      data_bonifico: '26/10/2022',
      id_bonifico: 'BB1122334',
    },
    {
      id: 'pm_FFasd1YpIHR2Kx1sUUyA0715zq',
      licenza: 'XAF123',
      stato: 'Disputa',
      importo: '500,00 €',
      fattura_xml: '2022/0724',
      data_fattura: '23/10/2022',
      bonifico: '2400,00 €',
      data_bonifico: '24/10/2022',
      id_bonifico: 'KK159753',
    },
    {
      id: 'pm_1Ll8abcgre83bg5zq',
      licenza: 'TAT554',
      stato: 'Riuscito',
      importo: '380,00 €',
      fattura_xml: '2022/0650',
      data_fattura: '17/09/2022',
      bonifico: '1250,00 €',
      data_bonifico: '20/09/2022',
      id_bonifico: 'HB459862',
    },
  ],

  tableFatture: [
    {
      numero: '2022/0721',
      data: '20/10/2022',
      importo: "975,00 €",
      stato: 'Inviata',
      incassato: 'Pagata',
      bonifico: '1500,00 €',
      data_bonifico: '21/10/2022',
      id_bonifico: 'AB112233',
      licenza: 'XYZ123'
    },
    {
      numero: '2022/0722',
      data: '21/10/2022',
      importo: "975,00 €",
      stato: 'Da inviare',
      incassato: 'Pagata',
      bonifico: '1100,00 €',
      data_bonifico: '26/10/2022',
      id_bonifico: 'CC159789',
      licenza: 'ATG486'
    },
    {
      numero: '2022/0723',
      data: '22/10/2022',
      importo: "975,00 €",
      stato: 'Scartata',
      incassato: 'Da Pagare',
      bonifico: '',
      data_bonifico: '',
      id_bonifico: '',
      licenza: 'ITY234'
    },
  ],
  /* eslint-disable global-require */
}

mock.onGet('/test-clienti-show/licenze').reply(() => [200, data.tableLicenze])
mock.onGet('/test-clienti-show/pagamenti').reply(() => [200, data.tablePagamenti])
mock.onGet('/test-clienti-show/fatture').reply(() => [200, data.tableFatture])
